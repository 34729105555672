import { action, makeAutoObservable } from 'mobx';
import { apiClient } from "../utils/api";
import { IUser } from "../intarfaces/IUsersResponse";
import { IFundraisersTableData, IStripeAccount } from "../intarfaces/IFundraisers";
import { BusinessModel, FundStatus } from "../constants/Fundraisers";

class FundraisersStore {

  isLoading = false;
  selectedFundraiser?: IFundraisersTableData;
  selectedFundraiserStripeAccounts: IStripeAccount[] = [];
  salesManagers: Array<IUser> = [];

  constructor() {
    makeAutoObservable(this)
  };

  setSelectedFundraiser = (fundraiser: IFundraisersTableData) => {
    this.selectedFundraiser = fundraiser;
    Object.assign(this.selectedFundraiser, {...fundraiser});
  }

  @action.bound
  setStatus = async (eventId: number, status: string) => {
    await apiClient.updateEvent({status}, eventId);
  }

  @action.bound
  setAssignedUserId = async (assignedUserId: number) => {
    if (this.selectedFundraiser) {
      this.selectedFundraiser.assignedUserId = assignedUserId;
      Object.assign(this.selectedFundraiser, {...this.selectedFundraiser});
      await apiClient.updateEvent({assignedUserId}, this.selectedFundraiser.id);
    }
  }

  @action.bound
  deleteEvent = async () => {
    if (this.selectedFundraiser) {
      this.selectedFundraiser.status = FundStatus.DELETED;
      Object.assign(this.selectedFundraiser, {...this.selectedFundraiser});
      await apiClient.deleteEvent(this.selectedFundraiser.id);
    }
  }

  @action.bound
  setClosed = async (closed: boolean) => {
    if (this.selectedFundraiser) {
      this.selectedFundraiser.status = closed ? FundStatus.CLOSED : FundStatus.APPROVED;
      Object.assign(this.selectedFundraiser, {...this.selectedFundraiser});
      await apiClient.updateEvent({closed}, this.selectedFundraiser.id);
    }
  }

  @action.bound
  setCommentsHidden = async (hideComments: boolean) => {
    if (this.selectedFundraiser) {
      this.selectedFundraiser.hideComments = hideComments;
      Object.assign(this.selectedFundraiser, {...this.selectedFundraiser});
      await apiClient.updateEventOption({hideComments}, this.selectedFundraiser.id);
    }
  }

  @action.bound
  setBookOptInEnabled = async (bookOptIn: boolean) => {
    if (this.selectedFundraiser) {
      this.selectedFundraiser.bookOptIn = bookOptIn;
      Object.assign(this.selectedFundraiser, {...this.selectedFundraiser});
      await apiClient.updateEventOption({bookOptIn}, this.selectedFundraiser.id);
    }
  }

  @action.bound
  setAffiliatePartnerId = async (affiliatePartnerId: string) => {
    if (this.selectedFundraiser) {
      this.selectedFundraiser.affiliatePartnerId = affiliatePartnerId;
      Object.assign(this.selectedFundraiser, {...this.selectedFundraiser});
      await apiClient.updateEvent({affiliatePartnerId}, this.selectedFundraiser.id);
    }
  }

  @action.bound
  setBusinessModel = async (businessModel: BusinessModel) => {
    if (this.selectedFundraiser) {
      this.selectedFundraiser.businessModel = businessModel;
      Object.assign(this.selectedFundraiser, {...this.selectedFundraiser});
      await apiClient.updateEventOption({businessModel}, this.selectedFundraiser.id);
    }
  }

  fetchStripeAccounts = async () => {
    this.selectedFundraiserStripeAccounts = [];
    if(this.selectedFundraiser) {
      this.selectedFundraiserStripeAccounts = await apiClient.getStripeAccounts(this.selectedFundraiser?.owner.id || 0);
    }
  }

  setActiveStripeAccount = async (accId: number) => {
    if(this.selectedFundraiser) {
       await apiClient.setActiveStripeAccount(this.selectedFundraiser?.owner.id || 0, accId);
       this.fetchStripeAccounts();
    }
  }

  fetchSalesManagers = async () => {
    const data = await apiClient.getUsers({role: 'SM'});
    this.salesManagers = data.items;
  }
}

export default new FundraisersStore();