import React, { useEffect } from 'react';
import { Checkbox, Dropdown, Menu, Popconfirm, Select } from 'antd';
import ActionIcon from "../../assets/images/action.svg";
import ActionWhiteIcon from "../../assets/images/action_white.svg";

import fundraisersStore from '../../stores/fundraisersStore';
import css from "./FundraiserDetails.module.scss"
import { IFundraisersTableData } from "../../intarfaces/IFundraisers";
import { observer } from "mobx-react-lite";
import { BusinessModel, FundStatus } from "../../constants/Fundraisers";

const Option = Select.Option;


interface IProps {
  onChange: () => void;
  fundraiser: IFundraisersTableData | null
}

const FundraisersDetails = observer(({fundraiser, onChange}: IProps) => {
  const store = fundraisersStore;
  if (!fundraiser) return null;

  const {selectedFundraiser, selectedFundraiserStripeAccounts: stripeAccounts} = store;

  useEffect(() => {
    store.setSelectedFundraiser(fundraiser);
    store.fetchStripeAccounts();
    // eslint-disable-next-line
  }, []);


  const onStripeAccountChange = (accId: any) => {
    store.setActiveStripeAccount(accId);
    onChange();
  }


  const onBookOptInEnabled = (e: any) => {
    onChange();
    store.setBookOptInEnabled(e.target.checked);
  }
  const onHideCommentChange = (e: any) => {
    onChange();
    store.setCommentsHidden(e.target.checked);
  }

  const onAssignedUserIdChange = (userId: number) => {
    onChange();
    store.setAssignedUserId(userId);
  }

  const onBusinessModelChange = (businessModel: BusinessModel) => {
    onChange();
    store.setBusinessModel(businessModel);
  }

  const onAffiliatePartnerChange = (affiliatePartnerId: string) => {
    onChange();
    store.setAffiliatePartnerId(affiliatePartnerId);
  }

  const handleDelete = () => {
    onChange();
    store.deleteEvent();
  }

  const handleClose = () => {
    onChange();
    store.setClosed(true);
  }

  const handleOpen = () => {
    onChange();
    store.setClosed(false);
  }

  const statusActions = () => (
    <Menu>
      {selectedFundraiser?.status === FundStatus.APPROVED ||
      selectedFundraiser?.status === FundStatus.PREPARING ||
      selectedFundraiser?.status === FundStatus.FUNDRAISING ?
        <Menu.Item>
          <Popconfirm title="Are you sure you want to close fundraiser? " okText="CLOSE" onConfirm={handleClose}>
            <div>Close</div>
          </Popconfirm>
        </Menu.Item> : null}

      {selectedFundraiser?.status === FundStatus.CLOSED &&
          <Menu.Item onClick={handleOpen}>
              <div>Reopen</div>
          </Menu.Item>}

      {selectedFundraiser?.status === FundStatus.CLOSED &&
          <Menu.Item>
              <Popconfirm title="Are you sure you want to delete fundraiser? You cannot undo this!"
                          onConfirm={handleDelete}
                          okText="DELETE">
                  <div>Delete</div>
              </Popconfirm>
          </Menu.Item>}
    </Menu>
  );

  return (
    <div className={css.wrapper}>
      <section>
        <div className={css.label}>Fundraiser Status</div>
        <div>
          {selectedFundraiser &&
              <Dropdown overlay={statusActions()} trigger={['click']}>
                  <div className={`${css.status} ${css[selectedFundraiser.status]}`}>{selectedFundraiser.status}
                    {selectedFundraiser?.status !== FundStatus.DELETED &&
                        <img src={selectedFundraiser?.status === FundStatus.APPROVED ? ActionIcon : ActionWhiteIcon}
                             className={css.buttonIcon} alt=""/>}
                  </div>
              </Dropdown>}

          <br/>
          {selectedFundraiser?.status === FundStatus.FUNDRAISING &&
              <>
                  <small className={css.desc}>
                      Fundraising status means fundraiser is started, there are donations already.
                  </small></>}

          {selectedFundraiser?.status === FundStatus.PREPARING &&
              <>
                  <small className={css.desc}>
                      Preparing status means fundraiser is configured (Stripe account is connected), but no donations
                      yet.
                  </small>
              </>}

          {selectedFundraiser?.status === FundStatus.APPROVED &&
              <>
                  <small className={css.desc}>
                      Approved status means fundraiser is created,<br/> but not configured yet (no Stripe account
                      connected)
                  </small></>}

          {selectedFundraiser?.status === FundStatus.CLOSED &&
              <>
                  <small className={css.desc}>
                      Closed status means fundraiser is finished or closed for other reason by EA or SA.
                  </small></>}
        </div>
      </section>

      <section>
        <div className={css.label}>Business Model</div>
        <div>
          <Select value={selectedFundraiser?.businessModel} onChange={onBusinessModelChange}>
            <Option value={BusinessModel.tips}>Donor Tips</Option>
            <Option value={BusinessModel.fee}>Fixed Fee</Option>
            <Option value={BusinessModel.flat}>Flat Fee</Option>
          </Select><br/>
          {selectedFundraiser?.businessModel === BusinessModel.fee &&
              <small className={css.desc}>
                  Flat-fee percentage deducted per online donation transaction.
              </small>}
          {selectedFundraiser?.businessModel === BusinessModel.tips &&
              <small className={css.desc}>
                  Generosity of donor tips cover the platform costs of your fundraiser.
              </small>}
        </div>
      </section>

      <section>
        <div className={css.label}>Affiliate Partner</div>
        <div>
          <Select style={{minWidth: 180}} value={selectedFundraiser?.affiliatePartnerId || ''}
                  onChange={onAffiliatePartnerChange}>
            <Option value={''}>None</Option>
            <Option value={'GVG-66'}>GVG-66 (Global Vending Group)</Option>
          </Select><br/>
        </div>
      </section>

      <section>
        <div className={css.label}>Book Opt-In</div>
        <div>
          <Checkbox checked={selectedFundraiser?.bookOptIn} onChange={onBookOptInEnabled}>Enabled</Checkbox><br/>
          <small className={css.desc}>
            Book Opt-In option is available on Donation Page
          </small>
        </div>
      </section>

      <section>
        <div className={css.label}>EA Active Stripe Account</div>
        <div>
          <Select style={{minWidth: 150}} value={stripeAccounts.find(acc => acc.active)?.oid || '0'}
                  onChange={onStripeAccountChange}>
            <Option value={'0'}>No active account</Option>
            {stripeAccounts.map(acc => <Option value={acc.oid}>{acc.display_name} / {acc.email}</Option>)}
          </Select><br/>
          <small className={css.desc}>
            Choose No active account to unlink the Stripe account from the fundraiser.
          </small>
        </div>
      </section>

      <section>
        <div className={css.label}>Assigned Sales Manager</div>
        <div>
          <Select value={selectedFundraiser?.assignedUserId} onChange={onAssignedUserIdChange}>
            <Option value={0}>Not assigned</Option>
            {store.salesManagers.map(sm => <Option key={sm.id} value={sm.id}>{sm.firstName} {sm.lastName}</Option>)}
          </Select><br/>
          <small className={css.desc}>
            Assign Sales Manager (SM) to this fundraiser.<br/>
            This fundraiser and its team members will be available for selected SM account.
          </small>
        </div>
      </section>
      <section>
        <div className={css.label}>Comments on Donation page</div>
        <div>
          <Checkbox checked={selectedFundraiser?.hideComments} onChange={onHideCommentChange}>Hide
            comments</Checkbox><br/>
          <small className={css.desc}>
            Turn checkbox on if need hide comments (donor messages)<br/> on the <a href={fundraiser.shareLink}
                                                                                   rel="noreferrer"
                                                                                   target="_blank">Donation page</a>
          </small>
        </div>
      </section>
    </div>
  )
})


export default FundraisersDetails;
